import PropTypes from 'prop-types';
import { setFragment } from 'bv-helpers/location';
import { Button, Card, Icon } from 'bv-components';
import VerificationCardStatusV2 from './verification_card_status_v2';
import { CARD_STATUSES, getLabel } from '../helpers';

const VerificationCardV2 = (props) => {
  const {
    sticky,
    status,
    feedback,
    title,
    description,
    button,
    handleCloseIconClick,
    handleIframeCTAClick,
    kycProviders,
    kycStrategyId,
    internalScanRef,
  } = props;

  return (
    <Card className="verification-card">
      {(sticky) && (
        <Button default className="verification-card__close-btn">
          <Icon id="close" active onClick={handleCloseIconClick} />
        </Button>
      )}
      <h3 className="verification-card__title">{title}</h3>
      <p className="verification-card__description">{description}</p>
      {(status === CARD_STATUSES.new && !feedback) ? (
        <div className="kyc-sdk-buttons">
          {kycProviders?.map((provider) => (
            <Button
              key={provider.id}
              label={getLabel(provider, button)}
              inverse
              onClick={() => {
                if (provider.integrationType === 'IFRAME') {
                  handleIframeCTAClick({
                    internalScanRef,
                    kycStrategyId,
                    integrationType: provider.integrationType,
                  });
                } else {
                  setFragment(provider.urls.MOBI);
                }
              }}
              secondary
            />
          ))}
        </div>
      ) : (
        <VerificationCardStatusV2
          status={status}
          feedback={feedback}
          handleIframeCTAClick={handleIframeCTAClick}
          kycStrategyId={kycStrategyId}
          internalScanRef={internalScanRef}
          kycProviders={kycProviders}
          CTALabel={button}
        />
      )}
    </Card>
  );
};

VerificationCardV2.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  feedback: PropTypes.string,
  handleIframeCTAClick: PropTypes.func.isRequired,
  sticky: PropTypes.bool.isRequired,
  handleCloseIconClick: PropTypes.func.isRequired,
  kycProviders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    integrationType: PropTypes.string,
    name: PropTypes.string,
    urls: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  })).isRequired,
  kycStrategyId: PropTypes.number.isRequired,
  internalScanRef: PropTypes.string.isRequired,
};

VerificationCardV2.defaultProps = {
  feedback: null,
};

export default VerificationCardV2;
