import PropTypes from 'prop-types';
import { Icon, Button } from 'bv-components';
import { setFragment } from 'bv-helpers/location';
import { t } from 'bv-i18n';
import { CARD_STATUSES, getLabel } from '../helpers';

const VerificationCardStatus = ({
  status,
  feedback,
  handleIframeCTAClick,
  internalScanRef,
  kycProviders,
  kycStrategyId,
  CTALabel,
}) => (
  (feedback) ? (
    <>
      <p className="verification-card__status">
        <Icon id="danger" />
        <span>{t(feedback)}</span>
      </p>
      <div className="kyc-sdk-buttons">
        {kycProviders?.map((provider) => (
          <Button
            key={provider.id}
            label={getLabel(provider, CTALabel)}
            inverse
            onClick={() => {
              if (provider.integrationType === 'IFRAME') {
                handleIframeCTAClick({
                  internalScanRef,
                  kycStrategyId,
                  integrationType: provider.integrationType,
                });
              } else {
                setFragment(provider.urls.MOBI);
              }
            }}
            secondary
          />
        ))}
      </div>
    </>
  ) : (
    <>
      {(status === CARD_STATUSES.pending) && (
        <p className="verification-card__status">
          <Icon id="question" />
          <span>{t('javascript.kyc.pending_card_status')}</span>
        </p>
      )}
      {(status === CARD_STATUSES.completed) && (
        <p className="bvs-text-success verification-card__status">
          <Icon id="check" />
          <span>{t('javascript.kyc.successful_card')}</span>
        </p>
      )}
    </>
  )
);

VerificationCardStatus.propTypes = {
  status: PropTypes.string.isRequired,
  CTALabel: PropTypes.string.isRequired,
  feedback: PropTypes.string,
  kycProviders: PropTypes.arrayOf(Object).isRequired,
  kycStrategyId: PropTypes.string.isRequired,
  handleIframeCTAClick: PropTypes.func,
  internalScanRef: PropTypes.string,
};

VerificationCardStatus.defaultProps = {
  feedback: null,
  handleIframeCTAClick: () => {},
  internalScanRef: '',
};

export default VerificationCardStatus;
