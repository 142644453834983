import { connect } from 'react-redux';
import {
  composeKycStrategiesV2, anyKycStrategiesV2,
} from '../selectors/strategies';
import { anyRestrictions, composeRestrictions } from '../selectors/restrictions';
import {
  closeVerificationCard,
  closeVerificationIframe,
  hideRestrictionsList,
  openRestrictionsList,
  openVerificationIframe,
  messageEventHandler,
} from '../duck';
import App from './app';

const mapStateToProps = (state) => ({
  verificationIframeIsOpened: state.verificationZone.verificationIframeIsOpened,
  verificationIframeUrl: state.verificationZone.verificationIframeUrl,
  verificationIframeToken: state.verificationZone.verificationIframeToken,
  verificationIframeLocale: state.verificationZone.verificationIframeLocale,
  verificationConfigIsLoading:
    state.verificationZone.verificationConfigIsLoading,
  verificationCardIsClosing: state.verificationZone.verificationCardIsClosing,
  restrictions: composeRestrictions(state),
  restrictionsListIsOpened: state.verificationZone.restrictionsListIsOpened,
  anyRestrictions: anyRestrictions(state),
  anyKycStrategiesV2: anyKycStrategiesV2(state),
  kycStrategiesV2: composeKycStrategiesV2(state),
  kycStrategiesV2WereLoaded: state.session.kycStrategiesV2WereLoaded,
  messageEventHandler,
});

const mapDispatchToProps = {
  openVerificationIframe,
  closeVerificationIframe,
  closeVerificationCard,
  openRestrictionsList,
  hideRestrictionsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
