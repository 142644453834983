import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import ToggleableContent from 'BVComponents/toggleable_content';
import { withFold } from 'bv-hocs';
import { linkLocalizer } from 'bv-helpers/locale';
import { t } from 'bv-i18n';

const ExplanatoryText = ({ folded, toggleFolded }) => {
  const contactUsText = reactStringReplace(
    t('javascript.kyc.verification_zone_desc4', { link1: '{link1}' }),
    '{link1}',
    () => (
      <a href={linkLocalizer('/pages/contact-us')}>
        {t('javascript.kyc.verification_zone_desc_link')}
      </a>
    ),
  );

  const content = (
    <>
      <p>{t('javascript.kyc.verification_zone_desc1')}</p>
      <p>
        *
        {t('javascript.kyc.verification_zone_desc2')}
      </p>
      <p>
        *
        {t('javascript.kyc.verification_zone_desc3')}
      </p>
      <p>{contactUsText}</p>
    </>
  );

  return (
    <ToggleableContent
      title={t('javascript.kyc.verifying_your_account')}
      folded={folded}
      toggleFolded={toggleFolded}
      content={content}
    />
  );
};

ExplanatoryText.propTypes = {
  folded: PropTypes.bool.isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default withFold(ExplanatoryText);
