import PropTypes from 'prop-types';

const ErrorMessage = ({ text }) => (
  <div className="verification-zone__error">
    <p className="bvs-header">{text}</p>
  </div>
);

ErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ErrorMessage;
