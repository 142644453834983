import { accountId } from 'bv-helpers/session';
import { t } from 'bv-i18n';
import {
  apiCloseVerificationCard,
  apiFetchVerificationIframeData,
  apiJumioReport,
} from './api';

export const CLOSE_VERIFICATION_IFRAME = 'verificationZone/CLOSE_VERIFICATION_IFRAME';
export const FETCH_VERIFICATION_IFRAME_SOURCE_INIT = 'verificationZone/FETCH_VERIFICATION_IFRAME_SOURCE_INIT';
export const FETCH_VERIFICATION_IFRAME_SOURCE_COMPLETE = 'verificationZone/FETCH_VERIFICATION_IFRAME_SOURCE_COMPLETE';
export const CLOSE_VERIFICATION_CARD_INIT = 'verificationZone/CLOSE_VERIFICATION_CARD_INIT';
export const CLOSE_VERIFICATION_CARD_COMPLETE = 'verificationZone/CLOSE_VERIFICATION_CARD_COMPLETE';
export const OPEN_RESTRICTIONS_LIST = 'verificationZone/OPEN_RESTRICTIONS_LIST';
export const HIDE_RESTRICTIONS_LIST = 'verificationZone/HIDE_RESTRICTIONS_LIST';

export const fetchVerificationIframeUrlInit = () => ({
  type: FETCH_VERIFICATION_IFRAME_SOURCE_INIT,
});

export const fetchVerificationIframeDataComplete = ({
  url = null,
  token = null,
  locale = null,
}) => ({
  type: FETCH_VERIFICATION_IFRAME_SOURCE_COMPLETE,
  data: { url, token, locale },
});

export const closeVerificationCardInit = () => ({
  type: CLOSE_VERIFICATION_CARD_INIT,
});

export const closeVerificationIframe = () => ({
  type: CLOSE_VERIFICATION_IFRAME,
});

export const closeVerificationCardComplete = () => ({
  type: CLOSE_VERIFICATION_CARD_COMPLETE,
});

export const openRestrictionsList = () => ({
  type: OPEN_RESTRICTIONS_LIST,
});

export const hideRestrictionsList = () => ({
  type: HIDE_RESTRICTIONS_LIST,
});

export const openVerificationIframe = (params) => (dispatch) => {
  dispatch(fetchVerificationIframeUrlInit());

  return apiFetchVerificationIframeData({ accountId: accountId(), params })
    .then((data) => {
      if (data.success) {
        dispatch(fetchVerificationIframeDataComplete(data));
      } else {
        dispatch(closeVerificationIframe());
        window.ModalHelper.showInfoMessage({
          message: t(`javascript.kyc.error_codes.${data.error}`),
          iconClosable: true,
        });
      }
    });
};

export const closeVerificationCard = (params) => (dispatch) => {
  dispatch(closeVerificationCardInit());

  return apiCloseVerificationCard({ accountId: accountId(), params })
    .then(() => {
      dispatch(closeVerificationCardComplete());
    });
};

export const messageEventHandler = (eventData) => apiJumioReport(eventData?.detail);

const initialState = {
  verificationIframeIsOpened: false,
  verificationConfigIsLoading: false,
  verificationIframeUrl: null,
  verificationIframeToken: null,
  verificationIframeLocale: null,
  verificationCardIsClosing: false,
  restrictionsListIsOpened: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLOSE_VERIFICATION_IFRAME:
      return {
        ...state,
        verificationIframeIsOpened: false,
        verificationIframeUrl: null,
        verificationIframeToken: null,
        verificationIframeLocale: null,
        verificationConfigIsLoading: false,
      };
    case FETCH_VERIFICATION_IFRAME_SOURCE_INIT:
      return {
        ...state,
        verificationIframeIsOpened: true,
        verificationConfigIsLoading: true,
      };
    case FETCH_VERIFICATION_IFRAME_SOURCE_COMPLETE:
      return {
        ...state,
        verificationConfigIsLoading: false,
        verificationIframeUrl: action.data.url,
        verificationIframeToken: action.data.token,
        verificationIframeLocale: action.data.locale,
      };
    case CLOSE_VERIFICATION_CARD_INIT:
      return {
        ...state,
        verificationCardIsClosing: true,
      };
    case CLOSE_VERIFICATION_CARD_COMPLETE:
      return {
        ...state,
        verificationCardIsClosing: false,
      };
    case OPEN_RESTRICTIONS_LIST:
      return {
        ...state,
        restrictionsListIsOpened: true,
      };
    case HIDE_RESTRICTIONS_LIST:
      return {
        ...state,
        restrictionsListIsOpened: false,
      };
    default:
      return state;
  }
};
