import { PropTypes } from 'prop-types';
import { Icon } from 'bv-components';

const RestrictionsInfo = ({ title, text }) => (
  <div className="restrictions-info">
    <Icon id="check" />
    <p className="bvs-header">{title}</p>
    <p className="bvs-h5">{text}</p>
  </div>
);

RestrictionsInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default RestrictionsInfo;
