import PropTypes from 'prop-types';
import { Modal } from 'bv-components';

const VerificationIframe = ({
  isOpened, url, token, locale, handleCloseIconClick,
}) => {
  if (!isOpened) return null;

  return (
    <Modal
      className="kyc-document-modal"
      onCloseClick={handleCloseIconClick}
      iconClosable
    >
      {
        token
          ? (
            <iframe
              src={`/jumio/index.html?token=${token}&locale=${locale}`}
              className="kyc-document-modal__iframe"
              sandbox="allow-forms allow-scripts allow-same-origin"
              allow="camera"
              title="Jumio WebSDK"
            />
          )
          : (
            <iframe
              src={url}
              className="kyc-document-modal__iframe"
              sandbox="allow-forms allow-scripts allow-same-origin"
              allow="camera"
              title="Jumio"
            />
          )
      }
    </Modal>
  );
};

VerificationIframe.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  url: PropTypes.string,
  token: PropTypes.string,
  locale: PropTypes.string,
  handleCloseIconClick: PropTypes.func.isRequired,
};

VerificationIframe.defaultProps = {
  url: PropTypes.string,
  token: PropTypes.string,
  locale: PropTypes.string,
};

export default VerificationIframe;
