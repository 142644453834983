import { createSelector } from 'reselect';
import { has } from 'underscore';
import { hasSportsbook, hasCasino } from 'global-config';
import { RESTRICTIONS_TRANSLATIONS_TOKENS } from '../helpers';

const getRestrictions = (state) => state.session.restrictions;

const restrictionCanPerformAction = (restriction) => {
  const actionsByIndex = {
    2: hasSportsbook,
    3: hasSportsbook,
    4: hasCasino,
  };

  if (has(actionsByIndex, restriction.index)) {
    return !!actionsByIndex[restriction.index];
  }

  return true;
};

export const composeRestrictions = createSelector(getRestrictions,
  (restrictions) => restrictions.map((canPerformAction, index) => ({
    canPerformAction,
    index,
    title: RESTRICTIONS_TRANSLATIONS_TOKENS[index],
  })).filter(restrictionCanPerformAction));

export const anyRestrictions = createSelector(composeRestrictions,
  (restrictions) => restrictions.some(({ canPerformAction }) => !canPerformAction));
