import { postJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const apiFetchVerificationIframeData = ({ accountId, params }) => (
  postJSON(`/security_checks/${locale()}/documents/uploads`, {
    accountId,
    kycStrategyPayload: {
      integrationType: params.integrationType,
      internalScanRef: params.internalScanRef,
      kycStrategyId: params.kycStrategyId,
    },
  })
);

export const apiCloseVerificationCard = ({ accountId, params }) => (
  postJSON(`/security_checks/${locale()}/documents/closing`, {
    accountId,
    internalScanRef: params.internalScanRef,
    kycStrategyId: params.kycStrategyId,
  })
);

export const apiJumioReport = ({ workflowExecutionId, successUpload, errorCode }) => (
  postJSON('/jumio/upload/report', {
    workflowExecutionId,
    successUpload,
    errorCode,
  })
);
