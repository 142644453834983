import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const RestrictionsList = (props) => {
  const { restrictions } = props;

  return (
    <div className="bvs-card account-status-card">
      <h3 className="account-status-card__title">{t('javascript.kyc.account_status_card.title')}</h3>
      <ul className="account-status-card__list">
        {restrictions.map((restriction) => (
          <li className="account-status-card__item" key={restriction.id}>
            <span className={`account-status-card__item-icon icon-${restriction.canPerformAction ? 'check' : 'error'}`} />
            <span className="account-status-card__item-text">{t(`javascript.${restriction.title}`)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

RestrictionsList.propTypes = {
  restrictions: PropTypes.arrayOf(Object).isRequired,
};

export default RestrictionsList;
