import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { t } from 'bv-i18n';
import VerificationCardV2 from './verification_card_v2';
import VerificationIframe from './verification_iframe';
import RestrictionsInfo from './restrictions_info';
import ExplanatoryText from './explanatory_text';
import ErrorMessage from './error_message';
import RestrictionsList from './restrictions_list';

const App = (props) => {
  const {
    restrictions,
    verificationConfigIsLoading,
    verificationCardIsClosing,
    verificationIframeIsOpened,
    verificationIframeUrl,
    verificationIframeToken,
    verificationIframeLocale,
    anyRestrictions,
    anyKycStrategiesV2,
    closeVerificationCard,
    closeVerificationIframe,
    openVerificationIframe,
    messageEventHandler,
    kycStrategiesV2,
    kycStrategiesV2WereLoaded,
  } = props;

  const [showError, setShowError] = useState(false);
  const [errorTimeoutID, setErrorTimeoutID] = useState(0);
  const [strategies, setStrategies] = useState(kycStrategiesV2);

  useEffect(() => {
    window.addEventListener('JumioKYX', messageEventHandler);
    return () => window.removeEventListener('JumioKYX', messageEventHandler);
  }, []);

  useEffect(() => { setStrategies(kycStrategiesV2); }, [kycStrategiesV2]);

  useEffect(() => {
    if (!kycStrategiesV2WereLoaded) {
      const timeoutID = setTimeout(() => {
        setShowError(true);
      }, 20000);

      setErrorTimeoutID(timeoutID);
    } else if (errorTimeoutID) {
      clearTimeout(errorTimeoutID);
      setShowError(false);
    }
  }, [kycStrategiesV2WereLoaded]);

  if (showError) {
    return (
      <ErrorMessage text={t('javascript.kyc.timeout_error')} />
    );
  }

  if (!kycStrategiesV2WereLoaded) return <Spinner />;
  if (verificationConfigIsLoading || verificationCardIsClosing) return <Spinner />;

  if (!anyRestrictions && !anyKycStrategiesV2) {
    return (
      <>
        <RestrictionsList
          restrictions={restrictions}
          anyRestrictions={anyRestrictions}
        />
        <RestrictionsInfo
          title={t('javascript.kyc.fully_verified_account.title')}
          text={t('javascript.kyc.fully_verified_account.text')}
        />
      </>
    );
  }

  return (
    <div className="verification-zone">
      <RestrictionsList
        restrictions={restrictions}
        anyRestrictions={anyRestrictions}
      />
      {strategies.map((strategy) => (
        <VerificationCardV2
          key={strategy.kycStrategyId}
          handleIframeCTAClick={openVerificationIframe}
          handleCloseIconClick={() => closeVerificationCard({
            internalScanRef: strategy.internalScanRef,
            kycStrategyId: strategy.kycStrategyId,
          })}
          {...strategy}
        />
      ))}
      <ContactUsFooter />
      <VerificationIframe
        isOpened={verificationIframeIsOpened}
        handleCloseIconClick={closeVerificationIframe}
        url={verificationIframeUrl}
        token={verificationIframeToken}
        locale={verificationIframeLocale}
      />
      <ExplanatoryText />
    </div>
  );
};

App.propTypes = {
  verificationIframeIsOpened: PropTypes.bool.isRequired,
  openVerificationIframe: PropTypes.func.isRequired,
  closeVerificationIframe: PropTypes.func.isRequired,
  verificationIframeUrl: PropTypes.string,
  verificationIframeToken: PropTypes.string,
  verificationIframeLocale: PropTypes.string,
  verificationConfigIsLoading: PropTypes.bool.isRequired,
  restrictions: PropTypes.arrayOf(Object).isRequired,
  restrictionsListIsOpened: PropTypes.bool.isRequired,
  openRestrictionsList: PropTypes.func.isRequired,
  anyKycStrategiesV2: PropTypes.bool,
  hideRestrictionsList: PropTypes.func.isRequired,
  closeVerificationCard: PropTypes.func.isRequired,
  verificationCardIsClosing: PropTypes.bool.isRequired,
  anyRestrictions: PropTypes.bool.isRequired,
  messageEventHandler: PropTypes.func.isRequired,
  kycStrategiesV2: PropTypes.arrayOf(Object),
  kycStrategiesV2WereLoaded: PropTypes.bool,
};

App.defaultProps = {
  verificationIframeUrl: null,
  verificationIframeToken: null,
  verificationIframeLocale: null,
  anyKycStrategiesV2: false,
  kycStrategiesV2: [],
  kycStrategiesV2WereLoaded: false,
};

export default App;
