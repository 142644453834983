import PropTypes from 'prop-types';
import ToggleIcon from './toggle_icon';

const ToggleableContent = ({
  title, folded, toggleFolded, content,
}) => (
  <div className="bvs-toggleable">
    <h3 className="bvs-toggleable__title" onClick={toggleFolded}>
      {title}
      <ToggleIcon folded={folded} big solid />
    </h3>
    {!folded && (
      <div className="bvs-toggleable__content">
        {content}
      </div>
    )}
  </div>
);

ToggleableContent.propTypes = {
  folded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleFolded: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default ToggleableContent;
